@import "mixins"
@layer button
  .button
    @include flex
    justify-content: center
    line-height: 12px
    font-size: 12px
    border: 0
    text-transform: uppercase
    @include bold
    padding-block: 0
    padding-inline: 0

  .baseButton
    padding: 0 8px
    height: 36px
    min-width: 90px
    color: var(--button_color)
    border-radius: var(--base_borderRadius)

  .primary
    background: rgb(var(--particularColor))

    &:active
      background: var(--button_primaryActiveBg)

  .secondary
    background: #dfe6f3
    color: var(--txt_color)

    &:active
      background: #A7B6D3

  .thirdary
    background: var(--button_thirdaryBg)
    color: var(--button_thirdaryColor)

    &:active
      background: var(--button_thirdaryActiveBg)

  .disabled
    opacity: 0.5
    pointer-events: none
